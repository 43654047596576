import { useBlockProps } from "@wordpress/block-editor";

export default function save({ attributes }) {
  return (
    <div {...useBlockProps.save()}>
      <style type="text/css">{attributes.css}</style>
      <div className="ca-galerie-grid">
        {attributes.items &&
          attributes.items.map((item, index) => (
            <div key={index} className={"ca-galerie-grid-item"}>
              <img
                className={"ca-galerie-grid-item-inner"}
                src={item.metadata["full"].file_url}
              />
            </div>
          ))}
      </div>
    </div>
  );

  return (
    <div {...useBlockProps.save()}>
      <style type="text/css">{attributes.css}</style>
      <div className="ca-galerie-grid">
        {() => {
          console.log("xx");
        }}
        <div>{context}</div>
        {attributes.items &&
          attributes.items.map((item, index) => (
            <div key={index} className={"ca-galerie-grid-item"}>
              <img
                className={"ca-galerie-grid-item-inner"}
                src={item.metadata["full"].file_url}
              />
            </div>
          ))}
      </div>
    </div>
  );
}
