import { __ } from "@wordpress/i18n";
import {
  useBlockProps,
  InspectorControls,
} from "@wordpress/block-editor";
import {
  RangeControl,
  PanelBody,
  TextControl,
} from "@wordpress/components";
import { useEffect, useState } from "@wordpress/element";
import apiFetch from "@wordpress/api-fetch";
import { select } from "@wordpress/data";
import { Button } from "@wordpress/components";
import { SelectControl } from "@wordpress/components";

export default function Edit({ attributes, ...props }) {
  const [galerie4Fields, setGalerie4Fields] = useState([]);
  const post = select("core/editor").getCurrentPost();
  const editedSlug = select("core/editor").getEditedPostSlug();
  const [forceRefetch, setForceRefetch] = useState(false);
  const [items, setItems] = useState([]);

  console.log(post, editedSlug);

  useEffect(() => {
    const fetchPosts = async () => {
      try {
        const response = await fetch(BlockBlockData.apiUrl);
        if (!response.ok) {
          throw new Error("Erro ao buscar posts");
        }
        const posts = await response.json();
      } catch (error) {
        console.error("Erro:", error);
      } finally {
      }
    };

    apiFetch({ path: "/acf/v1/fields" })
      .then((fieldGroups) => {
        for (const fieldGroup of fieldGroups[attributes.postType]) {
          if (fieldGroup.type === "galerie-4") {
            setGalerie4Fields((prev) => {
              let data = [...galerie4Fields];
              data.push(fieldGroup.name);
              return data;
            });
          }
        }
      })
      .catch((error) => {
        console.error("Error fetching ACF field groups:", error);
      });

    fetchPosts();
    console.log(attributes);
  }, [attributes.postType]); // O array vazio garante que o useEffect execute apenas uma vez

  // Only works in posts,pages,etc.. Not in templates.
  // This handles the preview of the images
  useEffect(() => {
    let postRestBase = "";
    if (post.type !== "wp_template") {
      apiFetch({ path: "/wp/v2/types" })
        .then((types) => {
          const postTypeSlug = types[post.type]?.rest_base;
          postRestBase = postTypeSlug;

          apiFetch({
            path: `/wp/v2/${postRestBase}/${post.id}?_fields=acf&acf_format=standard`, // corrigir
          })
            .then((post) => {
              console.log(post.acf);
              const items = post.acf[galerie4Fields[0]];
              setItems(items);
              props.setAttributes({ items: items });
              setForceRefetch(false);
            })
            .catch((error) => {
              console.error("Error fetching ACF fields:", error);
              setForceRefetch(false);
            });
        })
        .catch((error) => {
          console.error("Error fetching post type archive slug:", error);
        });
    }
  }, [galerie4Fields, forceRefetch]);

  useEffect(() => {
    console.log(attributes);
  }, [attributes, items, forceRefetch]);

  useEffect(() => {
    props.setAttributes({ costumField: galerie4Fields[0] });
  }, [galerie4Fields]);

  // Define Post Type
  useEffect(() => {
    if (post.type == "wp_template") {
      if (post.slug.startsWith("single-")) {
        const postType = post.slug.replace("single-", "");
        props.setAttributes({ postType: postType });
      }
    } else {
      props.setAttributes({ postType: post.type });
    }
  }, [attributes]);

  attributes.structure = <div></div>;

  const css = `
      .ca-galerie-grid{
        display: grid;
        row-gap: ${attributes.gridGapV}px;
        column-gap: ${attributes.gridGapH}px;
        grid-template-columns: repeat(${attributes.gridColumns}, 1fr);
      }

      .ca-galerie-grid-item{
        width: 100%; 
        overflow: hidden;
        ${
          attributes.borderRadius
            ? `border-radius: ${attributes.borderRadius}px;`
            : ""
        }
      }
  
      .ca-galerie-grid-item-inner{
        width: 100%; 
        height: 100%;
        object-fit: cover;
        ${
          attributes.aspectRatio !== ""
            ? `aspect-ratio: ${attributes.aspectRatio};`
            : ""
        }
      }

      @media (max-width: 768px) {
        .ca-galerie-grid{
          row-gap: ${attributes.mob_gridGapV}px;
          column-gap: ${attributes.mob_gridGapH}px;
          grid-template-columns: repeat(${attributes.mob_gridColumns}, 1fr);
        }
      }

      @media (min-width: 769px) and (max-width: 1080) {
        .ca-galerie-grid{
          row-gap: ${attributes.tab_gridGapV}px;
          column-gap: ${attributes.tab_gridGapH}px;
          grid-template-columns: repeat(${attributes.tab_gridColumns}, 1fr);
        }
      }
  `;

  useEffect(() => {
    props.setAttributes({ css: css });
  }, [css]);

  console.log(galerie4Fields);

  const setCSS = (css) => {
    props.setAttributes({ css: css });
  };

  return (
    <div {...useBlockProps()}>
      <InspectorControls key="setting">
        <PanelBody title="General" initialOpen>
          <div>
            <fieldset>
              <SelectControl
                label="Aspect Ratio"
                onChange={(v) => {
                  props.setAttributes({ aspectRatio: v });
                  setCSS(css);
                }}
                options={[
                  {
                    label: "Undefined",
                    value: "",
                  },
                  {
                    label: "1:1",
                    value: "1/1",
                  },
                  {
                    disabled: true,
                    label: "Vertical",
                    value: "",
                  },
                  {
                    label: "2:3",
                    value: "2/3",
                  },
                  {
                    label: "1:2",
                    value: "1/2",
                  },
                  {
                    label: "9:16",
                    value: "9/16",
                  },
                  {
                    label: "1:3",
                    value: "1/3",
                  },
                  {
                    disabled: true,
                    label: "Horizontal",
                    value: "",
                  },
                  {
                    label: "3:2",
                    value: "3/2",
                  },
                  {
                    label: "2:1",
                    value: "2/1",
                  },
                  {
                    label: "16:9",
                    value: "16/9",
                  },
                  {
                    label: "3:1",
                    value: "3/1",
                  },
                ]}
              />
            </fieldset>
            <fieldset>
              <SelectControl
                label="Image Size"
                onChange={(v) => {
                  props.setAttributes({ imageSize: v });
                  setCSS(css);
                }}
                options={[
                  {
                    label: "Full Size",
                    value: "full",
                  },
                  {
                    label: "Large",
                    value: "large",
                  },
                  {
                    label: "Medium",
                    value: "medium",
                  },
                  {
                    label: "Thumbnail",
                    value: "thumbnail",
                  }
                ]}
              />
            </fieldset>
            <fieldset>
              <TextControl
                label="Border Radius"
                onChange={(v) => {
                  props.setAttributes({ borderRadius: Number(v) });
                  setCSS(css);
                }}
                type="number"
                value={attributes.borderRadius}
              />
            </fieldset>
            <fieldset>
              <Button onClick={() => setForceRefetch(true)} variant="primary">
                Refetch Images
              </Button>
            </fieldset>
          </div>
        </PanelBody>
        <PanelBody title="Grid (default)" initialOpen>
          <div>
            <fieldset>
              <RangeControl
                label="Columns"
                step={1}
                separatorType="fullWidth"
                isShiftStepEnabled={true}
                separatorTypes={"none"}
                type="stepper"
                value={attributes.gridColumns}
                onChange={(v) => {
                  props.setAttributes({ gridColumns: Number(v) });
                  setCSS(css);
                }}
                min={1}
                max={12}
                withInputField={true}
              />
            </fieldset>
            <fieldset style={{ display: "flex", gap: "1rem" }}>
              <TextControl
                label="Horizontal Gap"
                onChange={(v) => {
                  props.setAttributes({ gridGapH: Number(v) });
                  setCSS(css);
                }}
                type="number"
                value={attributes.gridGapH}
              />
              <TextControl
                label="Vertical Gap"
                onChange={(v) => {
                  props.setAttributes({ gridGapV: Number(v) });
                  setCSS(css);
                }}
                type="number"
                value={attributes.gridGapV}
              />
            </fieldset>
          </div>
        </PanelBody>
        <PanelBody title="Grid (tablet)" initialOpen={false}>
          <div>
            <fieldset>
              <RangeControl
                label="Columns"
                step={1}
                separatorType="fullWidth"
                isShiftStepEnabled={true}
                separatorTypes={"none"}
                type="stepper"
                value={attributes.tab_gridColumns}
                onChange={(v) => {
                  props.setAttributes({ tab_gridColumns: Number(v) });
                  setCSS(css);
                }}
                min={1}
                max={12}
                withInputField={true}
              />
            </fieldset>
            <fieldset style={{ display: "flex", gap: "1rem" }}>
              <TextControl
                label="Horizontal Gap"
                onChange={(v) => {
                  props.setAttributes({ tab_gridGapH: Number(v) });
                  setCSS(css);
                }}
                type="number"
                value={attributes.tab_gridGapH}
              />
              <TextControl
                label="Vertical Gap"
                onChange={(v) => {
                  props.setAttributes({ tab_gridGapV: Number(v) });
                  setCSS(css);
                }}
                type="number"
                value={attributes.tab_gridGapV}
              />
            </fieldset>
          </div>
        </PanelBody>
        <PanelBody title="Grid (mobile)" initialOpen={false}>
          <div>
            <fieldset>
              <RangeControl
                label="Columns"
                step={1}
                separatorType="fullWidth"
                isShiftStepEnabled={true}
                separatorTypes={"none"}
                type="stepper"
                value={attributes.mob_gridColumns}
                onChange={(v) => {
                  props.setAttributes({ mob_gridColumns: Number(v) });
                  setCSS(css);
                }}
                min={1}
                max={12}
                withInputField={true}
              />
            </fieldset>
            <fieldset style={{ display: "flex", gap: "1rem" }}>
              <TextControl
                label="Horizontal Gap"
                onChange={(v) => {
                  props.setAttributes({ mob_gridGapH: Number(v) });
                  setCSS(css);
                }}
                type="number"
                value={attributes.mob_gridGapH}
              />
              <TextControl
                label="Vertical Gap"
                onChange={(v) => {
                  props.setAttributes({ mob_gridGapV: Number(v) });
                  setCSS(css);
                }}
                type="number"
                value={attributes.mob_gridGapV}
              />
            </fieldset>
          </div>
        </PanelBody>
      </InspectorControls>

      <style type="text/css">{css}</style>
      {items?.length > 0 ? (
        <div className="ca-galerie-grid">
          {items &&
            items.map((item, index) => (
              <div key={index} className={"ca-galerie-grid-item"}>
                <img
                  className={"ca-galerie-grid-item-inner"}
                  src={item.metadata["full"].file_url}
                />
              </div>
            ))}
        </div>
      ) : (
        <div
          style={{
            padding: "1.5rem",
            color: "#444",
            background: "#eee",
            border: "1px solid #000",
          }}
        >
          {post.type == "wp_template" && <div>This is a template.</div>}
          <div>No images available.</div>
        </div>
      )}
    </div>
  );
}
